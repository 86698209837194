<template>
             <usuario-reasignaciones-manual :expedientesactivos="expedientesactivos" :usuarios_tramitadores="usuarios_tramitadores" :id="id"  :expedientes="expedientes" />
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi'
//import UsuarioReasignacionesAutomatica from './secciones/UsuarioReasignacionesAutomatica.vue'
import UsuarioReasignacionesManual from './secciones/UsuarioReasignacionesManual.vue'
export default {
    components: {
       // 'usuario-reasignaciones-automatica': UsuarioReasignacionesAutomatica,
        'usuario-reasignaciones-manual': UsuarioReasignacionesManual,
    },
    props: ['id', 'usuario','expedientesactivos'],
    data() { 
        return {
                    usuarios_tramitadores: '',
                    expedientes: '',            
                }
    },
    methods: {
        async obtenerUsuarioTramitador(id) {
            /* GET  modulos/pwgsapi/index.php/usuarios/:id/reparto-manual */
            const api = new PwgsApi;  
            const datos = await api.get('usuarios/' + id + '/reparto-manual');
            this.usuarios_tramitadores = datos.tramitadores;
            this.expedientes = Object.values(datos.expedientes);
            this.idexpedientes = datos.expedientes;
            this.expedientes.pop();
            this.expedientes.pop();

        },
    },
    mounted() {
        this.obtenerUsuarioTramitador(this.id);
    },

    watch: {
        id() {
            this.obtenerUsuarioTramitador(this.id);
        }
    }
}
</script>
