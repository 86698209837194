<template>
   <div class="row">
      <div class="col-md-2">
         <div class="card card-primary">
            <div class="card-header">
               <h3 class="card-title">{{ $t('general.automatico') }}</h3>
            </div>
            <div align="center" class="card-body">
               <button type="button" @click="asignacionautomatica(id)" class="btn btn-default reparto automatico">{{
                  $t('general.automatico') }}</button>
            </div>
         </div>
      </div>
      <div class="col-md-8">
         <div class="card card-primary">
            <div class="card-header">
               <h3 class="card-title">{{ $t('general.manual') }}</h3>
            </div>
            <div class="card-body">
               <div class="row">
                  <div class="col-md-4">

                  </div>
                     <Button @click="repartomanual()">Guardar reparto manual</Button>
                  </div>
                  <p>&nbsp;</p>
                  <div class="row">
                     <div class="col-md-6">
                        <select multiple v-model="seleccionado" class="form-control origen" style="height: 100%;">
                           <option v-for="exp in expedientestram" :key="exp">{{exp}}</option>
                        </select>
                     </div>
                     <div class="col-md-6">
                        <div class="row">
                           <div class="tramitadores col-md-12">
                              <div v-for="user in tramitadoresexpedientes" :key="user" class="tramitador row" style="">
                                 <div class="col-md-2 botones">
                                    <button @click="moverexpedienteausuario(seleccionado,user)" type="button"
                                       class="btn btn-default form-control out"><i
                                          class="glyphicon glyphicon-chevron-right"></i>></button>
                                    <button @click="moverexpedientedeusuario(user)" type="button"
                                       class="btn btn-default form-control in"><i
                                          class="glyphicon glyphicon-chevron-left"></i>&#60;</button>
                                 </div>
                                 <div class="col-md-10">
                                    <span class="nombre">{{user.nombre}}</span>
                                    <select multiple v-model="user.expedientesamover" class="form-control destino"
                                       data-idusuario="">
                                       <option v-for="exp in user.expedientes" :key="exp">{{exp }}
                                       </option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
   props: ['usuario', 'id', 'usuarios_tramitadores', 'expedientes','expedientesactivos'],
   data() {
      return {
         seleccionado: [],
         seleccionado2: [],
         tramitadoresexpedientes: [],
         expedientestram: [],
         expedientesseleccionados: [],
         expedientesauxiliar: {},
      }
   },
   watch: {
      
      expedientesactivos() {

         let i = 0;
         for (let tramitador in this.expedientesactivos.datos.tramitadores) {
            let subidadatos = { nombre: this.expedientesactivos.datos.tramitadores[tramitador], expedientes: [], expedientesamover: [], idexpediente: [], idtramitador: tramitador };
            this.tramitadoresexpedientes[i] = subidadatos;
            i++;
         }
         this.expedientestram = this.expedientesactivos.datos.expedientes;
      },
      id() {
         this.expedientestram = this.expedientesactivos.datos.expedientes;
      }
   },
   methods: {
      getKeyByValue(object, value) {
   return Object.keys(object).find(key =>
      object[key] === value);
},
      async asignacionautomatica(id) {
         /* put  modulos/pwgsapi/index.php/usuarios/:id/reparto-automatico */
         try {
            const api = new PwgsApi;
            await api.put('usuarios/' + id + '/reparto-automatico');
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            this.$parent.$parent.sacarexpedientesactivos();
         }
         catch (error) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
         }
      },
      moverexpedienteausuario(expediente, usuario) { 
         for (let i = 0; i < this.tramitadoresexpedientes.length; i++){
            if (this.tramitadoresexpedientes[i].nombre == usuario.nombre) {
               for (let j = 0; j < expediente.length; j++){
                  let indice = this.getKeyByValue(this.expedientestram, expediente[j]);
                  this.expedientesauxiliar[indice] = expediente[j];
                  this.tramitadoresexpedientes[i].expedientes.push(expediente[j]);
                  this.tramitadoresexpedientes[i].idexpediente.push(indice);
                  delete this.expedientestram[indice];
               }

            }
         }

      },
      moverexpedientedeusuario(usuario) {
         for (let i = 0; i < this.tramitadoresexpedientes.length; i++) {
            if (this.tramitadoresexpedientes[i].nombre == usuario.nombre) {
               for (let j = 0; j < this.tramitadoresexpedientes[i].expedientesamover[j].length; j++) {
                  let indiceaux = this.getKeyByValue(this.expedientesauxiliar, this.tramitadoresexpedientes[i].expedientesamover[j]);   
                  this.expedientestram[indiceaux] = this.tramitadoresexpedientes[i].expedientesamover[j];
 
                  for (let k = 0; k < this.tramitadoresexpedientes[i].expedientes.length; k++){
                     if (this.tramitadoresexpedientes[i].expedientes[k] == this.tramitadoresexpedientes[i].expedientesamover[j]) {
                        this.tramitadoresexpedientes[i].expedientes.splice(k,1);
                        this.tramitadoresexpedientes[i].idexpediente.splice(k,1);
                     }
                  }
               }
            }
         }
      },
      async repartomanual() { 
         /* put   modulos/pwgsapi/index.php/usuarios/:id/reparto-manual */
         try {
            for (let i = 0; i < this.tramitadoresexpedientes.length; i++){
               if (this.tramitadoresexpedientes[i].idexpediente.length > 0) {
                  const api = new PwgsApi();
                  let subidadatos = { expedientes: this.tramitadoresexpedientes[i].idexpediente, id_usuario: this.tramitadoresexpedientes[i].idtramitador };
           await api.put('usuarios/' + this.$props.id + '/reparto-manual',subidadatos  );
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            this.$parent.$parent.sacarexpedientesactivos();
               }
            }
         }
         catch (error) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
         }
      },
     
   },
    mounted() {
      
   },
}
</script>